.featured-projects{
	margin-top: -50px;
	margin-bottom: 100px;
}

.projects{
	margin-top: 50px;
}

.contact-details{
	h3{
		@include font-bold;
	}
}


.quick-facts-list{
	background-color: $brand-color;
	padding: 35px;
	position: relative;
	margin-bottom: 75px;

	&::after{
		content: '';
		display: block;
		position: absolute;
		z-index: 2;
		width: 30px;
		height: 30px;
		transform:rotate(45deg);
		bottom: -15px;
		right: -15px;
		background: #000;
	}
	
	h3{
		@include heading-regular;
	}
	h3,
	li{
		color: #000;
	}
	li{
		border-bottom: 1px #fff solid;
		margin-bottom: 10px;
		padding-bottom: 15px;
	}
	ul{
		list-style: none;
		padding-left: 0;
		margin-top: 30px;
	}
	small{
		@include heading-bold;
		font-size: 0.8em;
		text-transform: uppercase;
	}
	p{
		@include heading-regular;
		font-size: 1.4em;
		line-height: 1.2em;
		color:#000;
		margin-bottom: 0;
	}

}

.row-content{
	margin-bottom: 75px;
}

.project-title{
	color: #fff;
	margin-bottom: 40px;
	margin-top: 0;
}

.indent-margin{
	margin-top: -125px;
}

@include media-breakpoint-down(md) {

	.person-profile{
		text-align: center;
	}

	.row-content{
		margin-bottom: 30px;
	}

}

@include media-breakpoint-down(sm) {

	.indent-margin{
		margin-top: 0px;
	}
	.row-content{
		margin-bottom: 15px;
	}
	.featured-projects{
		margin-top: 0;
		margin-bottom: 50px;
	}

}