textarea{
	resize:vertical;
	min-height: 150px;
	&.form-control{
		padding: 20px;
	}
	
}
input,
.form-control{
	box-shadow: none;
	// &:focus{
	// 	box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba($brand-lightorange,.6);
	// }
}

label{
	display: block;
	color: #fff;
	@include font-bold();
}

.radio input[type="radio"],
.radio-inline input[type="radio"], 
.checkbox input[type="checkbox"], 
.checkbox-inline input[type="checkbox"]{
	margin-left: 0;

}
.checkbox{
	position: relative; 
	label::after{
		color: white;
	}
	input:checked ~ label:before{
		background: #fff;
	}
} 

.radio{
	label::after{
		background: $brand-color;
		top: 0;
		left: 0;
		height: 19px;
		width: 19px;
		border:3px lighten($brand-color, 20%) solid ;
	}
	label::after{
		background: $brand-color;
		top: 0;
		left: 0;
		width: 18px;
		height: 18px;
		border:3px lighten($brand-color, 20%) solid ;
	}
} 

input.required::after, 
input[required]::after{
	content: '';
	font-family: 'Fontawesome';
	color: red;
	display: block;
	float: right;
}

.errmsg{
	@include font-bold;
	color: $error-color;
	padding: 10px 10px 8px;
	background: #EADFDF;
	margin-bottom: 15px;
}

.file-upload{
	clear: both;
	& > p{
		margin-bottom: 15px;
		font-size: 0.8em;
		font-style: italic;
		font-weight: bold;
		float: left;
	}
	.btn-file{
		position: relative;
		input[type=file]{
			font-size: 100px;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
		}
	}
}

legend{
	@include font-bold();
	font-weight: 700;
	line-height: 1.4em;
	color:  $brand-color;
	font-size: 1.8em;
	margin-bottom: 30px;
	border-bottom: 0;
	& ~ fieldset legend{
		font-size: 1.4em;

	}
}



.form-control{
	background: #fff;
	border-radius: 0;
	padding-left: 20px;
	padding-right: 20px;
	border:0;
	height: 50px;
	margin-bottom: 20px;
	& + small{
		display: block;
		margin-top: 10px;
		color: $brand-color;
		font-weight: 700;
		margin-bottom: 0;
		font-size: 0.8em;
		font-style: italic;
	}
}


button[data-form-add]{
	float: left;
	clear: both;
	margin-bottom: 30px;
}


@media(max-width: 767px){
	.form-control{
		height: 40px;
	}
}
