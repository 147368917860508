
footer{

	padding: 75px 0 100px;
	font-size: 1.2em;
	position: relative;
	@include link-color($brand-color, $brand-color);
	@include heading-bold();

	p{
		color: $brand-color;
		@include heading-bold();
		position: absolute;
		left: 0;
		top: 0;
		font-size: 20px;
		padding: 0 20px 0 15px;
	}
	a{
		position: absolute;
		font-size: 20px;
		right: 0;
		top:0;
		padding: 0 15px 0 20px;
		line-height: 1.7em;
	}
	hr{
		border-color: $brand-color;
		margin-top: 18px;
		width: calc(100% - 380px);
	}
}

@include media-breakpoint-down(md) {

	footer{
		padding: 75px 0;
	}

}

@include media-breakpoint-down(md) {

	footer{
		padding: 50px 0;
	}

}

