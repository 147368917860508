.accordian{
	margin-top: 30px;
	.card{
		border:0;
		background: transparent;
		margin-bottom: 20px;
	}
	.card-header{
		background: transparent;
		padding: .75rem 30px;
		border:0;
		h2{
			transition:0.2s ease-out color;
		}
		a{
			&:hover{
				h2{
					color: #ccb100;
				}
				.icon{
					svg{
						fill:  #ccb100;
					}
				}
			}
		}
		.icon {
			width: 25px;
			position: absolute;
			top: 16px;
			left: 0;
			svg{
				fill: $brand-color;
				transform:rotate(90deg);
				transition:0.2s ease-in-out transform, 0.2s ease-in-out color;
			}
		}
		.collapsed{
			svg{
				transform:rotate(0deg);
			}
		}
		h2{
			margin-bottom: 0.5em;
			font-size: 1.7em;
			margin-top: 0;
		} 
		h3{
			text-transform: uppercase;
			margin-bottom: 15px;
		}
		p{
			line-height: 1.3em;
		}
	}
	.card-body{
		padding: 0rem 30px;
	}
	.collapse{
		overflow: hidden;
	}
}


.panel-group{
	margin-bottom: 40px;
}
.panel-default{
	position: relative;
	border:0;
	box-shadow: none;
	& > .panel-heading{
		position: relative;
		border:0;
		background:none;
		overflow: hidden;
		width: 100%;
		padding: 20px 0 25px;
		margin-bottom: 25px;
		border-bottom: 1px #ededed solid;
		.panel-title{
			color: $brand-color;
			font-size: 20px;
			
			a{
				display: block;
				overflow: hidden; 
				width:100%;
				&:hover,
				&:focus{
					[class^="fa"]{
						color: $link-highlight;
					}
				}
			}
			[class^="fa"]{
				position: absolute;
				right: 0;
				top: 25px;
				font-size: 20px;
				color: $brand-color;
				transition:color 0.2s ease-in-out;
			}

		}
		& + .panel-collapse{
			& > .panel-body{
				border-top: 0;
			}
		}
	}
}

.panel{
	box-shadow: 0;
	.panel-body {
		padding: 0;
	}

	&:nth-last-child(1){
		& > .panel-heading{
			border-bottom: 0;
		}
	}
}

.img-accordion{
	overflow: hidden;
	width: 100%;
	padding-top: 20px;

	.images{

		position: relative;
		.img-container{
			position: absolute;
			opacity: 0;
			top:0;
			left: 0;
			width: 100%;
			transition:opacity 0.15s ease-in-out;
			&.active{
				opacity: 1;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	
	.img-accordion{
		.images{
			overflow: hidden;
			height: 400px;
			margin-bottom: 30px;
			.img-container{
				height: 100%;
			}
		}
	}

}

@include media-breakpoint-down(xs) {
	.img-accordion{
		.images{
			height: 250px;

		}
	}
}