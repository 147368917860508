//---- Nav
@import "_structure/_navigation-structure";



$bg-color: $brand-color; 
$nav-link-color: #fff;

$link-alignment: flex-end;

.header-wrap{
	background-image: linear-gradient(-180deg, rgba(20, 20, 20, 0.8) -20%, rgba(200, 200, 200, 0) 100%);
	transition: opacity 0.2s ease-in-out, top 0.2s ease-in-out;
	top: 0;
	opacity: 1;

	.navbar-brand{
		background-image: url(../assets/system/logo-white.png);
		width: 100%;
		margin-top: 0;
		padding-top: 0;
		display: block;
		a{
			width: 100%;
			height: 100%;
			display: block;
		}
	}

	&.color-nav{
		background-image: none;
		background-color: #ffffff;
		.navbar-brand{
			background-image: url('../assets/system/logo-red.png')
		}
		.main-nav.navbar.navbar-default {
			.navbar-nav {
				li{
					& > a{
						&:not(.btn){
							color: #ADAFAF;
							&:hover{
								color: $brand-color;
							}
						}
					}
				}
			}
		}
		.main-nav-trigger {
			.main-nav-icon{
				background: #888;
				&:after, 
				&:before{
					background: #888;
				}
			}
		}
	}
}

.main-nav.navbar.navbar-default {
	min-height: 0;
	background-color:transparent;
	z-index:3;
	align-items: flex-end;

	.color-nav &{
		@include link-color(#ADAFAF, $brand-color);
	}

	.navbar-header{
		width: 100%;
	}

	.navbar-nav{
		flex-direction:row;
		li {
			position: relative;
			@include font-regular;
			font-size: 16px;
			transition: opacity 0.2s ease-in-out;

			&.active{


				&::after{
					content: '';
					width: 100%;
					position: absolute;
					height: 2px;
					background: $brand-color;
				}
			}

			& > a:not(.btn) {
				color: $nav-link-color;
				text-transform: uppercase;
				@include font-bold;
				padding: 10px 20px 10px 20px;
				transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
				opacity: hidden;
				display: block;

				&:hover,
				&:focus,
				&:active {
					color: $brand-color;
					background-color: transparent;
				}	
			}
		}
		&.opened{
			li:not(.open){
				opacity: 0.2;
			}

		}
	}
}


@include media-breakpoint-up(lg) {

	.main-nav.navbar.navbar-default {
		.navbar-nav {
			margin-left: 0;
		}

	}
}


@include media-breakpoint-up(md) {

	.main-nav.navbar.navbar-default {

		.dropdown{
			.dropdown-menu{
				top: 112px;
				&.open{
					display: block;
				}
			}
		}


		.navbar-nav {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			width: 100%;

			flex-wrap:wrap;
			.phone-number {
				position: absolute;
				right: 0;
			}
		}
		.navbar-brand {
			width: 175px;
			transition: all 0.1s;
			margin-top: 20px;
			position: absolute;
			margin-top: 30px;
			padding: 0;
			height: auto;
			z-index: 9;
		}

	}
}

@include media-breakpoint-down(md) {

	.header-wrap{
		width: 100%;
		background-color: #242021;
		background-image: none;
		z-index: 9;


		.navbar-brand {
			a{
				width: 130px;
			}
		}
	}
	.page-wrapper {
		position: relative;
		z-index: 2;
		background-color: #ffffff;
		transform: translateZ(0);
		transition: transform 0.7s cubic-bezier(0.91, 0.01, 0.6, 0.99);
	}

	.main-nav.navbar.navbar-default {
		align-items: center;
		background-color: #000;
		background-image: url('../assets/system/logo-white.png');
		background-position: 50px 25px;
		background-repeat: no-repeat;
		background-size: 100px;
		opacity: 0; 
		visibility: hidden;

		.main-navigation-wrapper {
			padding: 65px 50px;
			transform: translateX(0%);
			transition: transform 0.7s cubic-bezier(0.91, 0.01, 0.6, 0.99);
		}

		.navbar-nav {
			flex-direction: column;
			li {
				float: none;
				font-size: 30px;
				position: relative;
				padding-left: 20px;
				opacity: 0;
				transform:translateY(15px);

				transition: transform 0.2s cubic-bezier(0.91, 0.01, 0.6, 0.99), opacity 0.2s cubic-bezier(0.91, 0.01, 0.6, 0.99) ;
				@for $i from 1 through 10 {
					&:nth-child(#{$i}n) {
						transition-delay: #{$i * 0.1}s;
					}
				}
				& > a:active,
				& > a:hover,
				& > a:focus,
				& > a:hover:before,
				& > a:focus:before {
					color: $brand-color;
					background: transparent;
				}
				a:not(.btn){
					color: #fff;
				}
				&:before{
					content:'';
					position: absolute;
					left: 0;
					top: 50%;
					transform:translateY(-50%);
					display: inline-block;
					background-color: #fff;
					height: 1px;
					width: 22px;
				}
			}

		}
	}

	.main-nav-trigger {
		right: 15px;
		top: 15px;
		height: 40px;
		width: 40px;
		background-color: #ffdd00;
		border-radius: 30px;
		transition: transform 0.2s;

		.main-nav-icon {

			@include center;
			width: 24px;
			height: 2px;

			background-color: #000;
			cursor: pointer;
		}
	}

	.navigation-is-open{
		overflow: hidden;
		.main-nav.navbar.navbar-default {

			.navbar-nav {
				li{
					opacity: 1;
					transform:translateY(0px);
					&.phone-number{
						position: absolute;
						bottom: 50px;
						left: 0;
						width: 100%;
						@include font-light;
						a{
							color: #ADAFAF;
							&:hover, 
							&:focus{
								color: $brand-color;
							}
						}
						&:before{
							display: none;
						}
					}
				}
			}
		}
		.header-wrap{
			.main-nav-trigger {
				.main-nav-icon{
					background: transparent;
					&:after, 
					&:before{
						background: #000;
					}
				}
			}

		}
	}
}


@include media-breakpoint-down(sm) {

	.main-nav.navbar.navbar-default {
		.dropdown-menu{
			.card{
				padding:0;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.header-wrap{
		top: 0px;
		transition: top 0.2s ease-in-out;
		*{
			transition: all 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		}
		&.shrink{
			background-image: none;
			background-color: #242021;
			.navbar-brand{
				width: 130px;
				padding-bottom: 0;
			}
			.main-nav.navbar.navbar-default {
				align-items: center;
				padding: 0;
				.navbar-nav {
					li{
						font-size: 14px;
						& > a{
							&:not(.btn){

								&:hover{
									color: $brand-color;
								}
							}
						}
					}
				}
			}
		}
	}
}

.dropdown-backdrop{
	z-index: 0;
	display: none;
}
