
@include media-breakpoint-down(sm) {


}

@include media-breakpoint-down(md) {
	.case-studies {
		h2 {
			font-size: 2em;
			line-height: 1.3;
		}
	}
}