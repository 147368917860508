.form-group{


	input{
		&:required {
			color: #dc3545;
		}
	}
	&.invalid{
		input{
			border: #dc3545 1px solid;
			color: #dc3545;
			&:focus{
				box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(220, 53, 69, 0.6);
			}
		}
	}
	&.is-valid{
		input{
			border: #28a745 1px solid;
			color: #28a745;
			&:focus{
				box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(40, 167, 69, 0.6);
			}
		}
	}

}

.invalid-feedback{
	color: #dc3545 !important;
	font-style: italic !important;
	font-weight: 500 !important;
	font-size: 85% !important;
}

.checkbox + .checkbox, 
.radio + .radio {
	margin-top: 10px;
}

.checkbox + .form-group{
	clear: both;
}
.half-checkboxes,
.half-radios{
	.checkbox,
	.radio{
		width: 50%;
		float: left;
	}
}
.third-checkboxes,
.third.radios{
	.checkbox,
	.radio{
		width: 33%;
		float: left;
	}
}

.inline-checks,
.inline-radios{

	overflow: hidden;
	h3{
		float: left;
		color: #fff;
		margin-right: 30px;
		@include heading-bold();
	}
	.checkbox,
	.radio,
	.custom-checkbox,
	.custom-radio{
		
		float: left;
		& + .checkbox,
		& + .radio,
		& +.custom-checkbox,
		& +	.custom-radio{
			margin-left: 30px;

		}
	}
}

@media(max-width:991px){
	.form-group{
		width: 100%;
		max-width: 100%;
	}
}
@media(max-width:767px){

	.four-col-inputs{
		.form-group{
			width: 50%;
		}
	}

}
@media(max-width:580px){
	.half-col-inputs{
		.form-group{
			max-width: 100%;
			width: 100%;
		}
	}
}



@media(max-width:767px){
	.third-checkboxes,
	.third.radios{
		.form-group{
			width: 50%;
			float: none;
		}
	}

}
@media(max-width:580px){
	.three-col-inputs,
	.half-col-inputs,
	.four-col-inputs{
		.form-group{
			width: 100%;
			float: none;
		}
	}
}
