.social-media{
	position: absolute;
	right: 0;
	padding: 0;
	list-style: none;
	display: inline-block;
	li{
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
		&:nth-child(1){
			-ms-transform: rotate(-90deg) translateX(10px) translateY(0px);
			transform: rotate(90deg) translateX(10px) translateY(0px);
			display: inline-block;
			color: #ADAFAF;
			margin-bottom: 35px;

		}
		a{
			color: #4D4F53;
			&:hover{
				color: $brand-color;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.social-media{ 
		position: relative;
		li{
			margin-bottom: 0;
			margin-top: 20px;
			display: inline-block;
			margin-right: 20px;
			&:nth-child(1){
				-ms-transform: rotate(0) translateX(0) translateY(0);
				transform: rotate(0) translateX(0) translateY(0);
				display: inline-block;
				color: #ADAFAF;
			}
		}
	}
}