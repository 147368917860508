.hero{
	height: 600px;
	width: 100%;
	position: relative;
	overflow: hidden;
	margin-bottom: 0;
	display: flex;
	transform:translateZ(-1px);

	&.hero-home{
		height: 100%;
		padding-bottom: 150px;
		
	}
	&:after{
		content: '';
		height: 10%;
		width: 100%;
		position: absolute;
		bottom:0;
		left: 0;
		z-index: 0;
	}

	.img-container{
		width:100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		z-index: -1;
		padding-bottom:0;


	}
	.blurb-container{
		background: rgba(#000, 0.8);
		padding: 60px 30px 75px 60px;
		margin-left: -30px;
		margin-top: 160px;
		h1{
			color:	$brand-color;
			font-size: 2.8em;
			margin-top: 0;
			margin-bottom: 0;
			line-height: 1.1em;
		}
	}
	.link-container{
		background: $brand-color;
		max-width: 200px;
		padding: 30px;
		margin-top: -40px;
		a{
			text-transform: uppercase;
			font-size: 1.1em;
			max-width: 115px;
			color: #000;
			display: block;
			line-height: 1.3em;
			@include heading-regular;
			svg{
				path,
				rect,
				polygon{
					transition:fill 0.2s ease-in-out;
				}
				polygon{
					transform:translateX(0);
					transition:transform 0.2s ease-in-out;
				}
			}
			&:hover {
				
				svg{
					
					polygon{
						transform:translateX(15px);
					}
				}
			}
		}
		.industry-svg{
			margin-bottom: 15px;
		}
		.see-more{
			margin-top: 15px;
		}
		svg{
			width: 30px;
		}
	}
}

.page-intro{
	width: 100%;
	position: relative;
	overflow: hidden;
	padding-top: 175px;
	margin-bottom: 50px;
}




@include media-breakpoint-down(md) {
	.hero{
		height: 400px;
		margin-bottom: 50px;

	}
}


@include media-breakpoint-down(sm) {

	.page-intro{
		padding-top: 100px;
	}
	
	.hero{
		margin-bottom: 0;
		height: 200px;

		&.hero-home{
			padding-bottom: 50px;
		}
		.blurb-container{
			padding-left: 30px;
			padding-right: 30px;
			h1{
				font-size: 2em;
			}
			margin-left: 0;
		}
		.link-container {
			max-width: 100%;
			padding: 15px 20px 23px 30px;
			margin-bottom: 30px;
			a{
				font-size: 16px;
				max-width: 100%;
			}
		}

		.arrow-btn{
			display: none;
		}
		&:not(.home){
			.reveal{
				padding-bottom: 50px;
			}

		}
	}
}