.contact-details{

	@include link-color(#fff, $brand-color);
	p{
		font-size: 1.2em;
	}
	.contact-detail{
		margin-bottom: 0;

		&:last-of-type{
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

}