 
//- Common Page elements

.reveal{ 
	visibility: hidden;
}

.lead{
	margin-top: 1em;
	font-size:1.5em;
	line-height: 1.5em;
	margin-bottom: 1em;
	@include heading-regular;
}

.btn.btn-primary,
.btn.btn-default{
	text-transform: uppercase;
	background-color: $brand-color;
	border-radius: 0; 
	border:0; 
	padding: 10px 35px; 
	font-size: 0.8em;
	transition: background-color 0.2s ease-in-out;
	color: #000;
	border:0;
	display: inline-block;
	@include heading-regular;
	&.active,
	&.acive:hover, 
	&.acive:focus,
	&:active,
	&:active:focus,
	&:hover,
	&:focus{
		background-color: $brand-color;
		border-color: $brand-color;
		box-shadow: none;
		color: #fff;
		outline: none;
	}
}

.btn.btn-arrow{
	text-transform: uppercase;
	background-color: $brand-color;
	border-radius: 0; 
	border:0; 
	padding: 10px 20px; 
	font-size: 0.9em;
	transition: background-color 0.2s ease-in-out;
	color: #000;
	border:0;
	@include heading-regular;

	svg{
		width: 25px;
	}
	&.active,
	&.acive:hover, 
	&.acive:focus,
	&:active,
	&:active:focus,
	&:hover,
	&:focus{
		background-color: $brand-color;
		border-color: $brand-color;
		box-shadow: none;
		color: #fff;
		outline: none;
	}
}

ol.number-list{
	list-style: none;
	padding-left: 0;
	margin-bottom: 40px;
	li{
		counter-increment: step-counter;
		position: relative;
		padding-left: 25px;
		&::before{
			content: counter(step-counter) ".";
			border-radius: 30px;
			color: #ffdd00;
			display: block;
			text-align: center;
			line-height: 30px;
			position: absolute;
			left: 0;
			top: 0;
			@include font-bold;
		}
	}
}


.img-container{
	max-width: 100%;
	height: 0;
	overflow: hidden;
	position: relative;
	padding-bottom: 56.5%;

	img,
	iframe{
		position: absolute;

		width: 100%;
		max-width: none;
		transform:translate(-50%, -50%);
		left: 50%;
		top: 50%;
		object-fit: cover;
	}

	.no-objectfit &{
		img{
			width: auto;

		}
	}
}




.warning{
	color: #FA5E3F;
}


.feature{

	&.feature-yellow{
		background: rgba($brand-color, 0.8);
		position: relative;
		padding-top: 50px;
		padding-bottom: 200px;
		p,h1,h2{
			color: #000;
		}

		.background-container{
			position: absolute;
			top: 0;
			background-image: url(/assets/system/bg-bw.jpg);
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			z-index: -1;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
	&.feature-white{
		background: #fff;
		padding-bottom: 100px;
		h2{
			color: #000;
			font-size: 1.4em;
			margin-bottom: 0.8em;
			text-transform: uppercase;
		}
	}
	.negative-margin{
		margin-top: -150px;
	}
}


.backdrop{
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding-bottom: 50px;
	

	&.background-picture{
		background-image: url('/assets/background-images/bg-bw.jpg');
		padding-top: 200px;
		background-position: bottom;
	}
	&.background-hex{
		background-image: url('/assets/background-images/bg-hexes.jpg');
		background-attachment: fixed;
	}
	&.background-tiles{
		background-image: url('/assets/background-images/bg-tiles.jpg');
		background-attachment: fixed;
	}
	&.background-bottom{
		background-image: url('/assets/background-images/bg-bottom.jpg');
	}
}

.page-title-container{
	margin-top: -80px;
	background:rgba(#000, 0.7);
	padding: 15px 30px;
	transform: translateZ(2px);



	h1{
		line-height: 1em;
		margin-bottom: 0;
		margin-top: 0;
	}
	&.svg{
		margin-top: -100px;
		padding: 45px;
		.svg-container{
			width: 70px;
			height: 70px;
			rect{
				fill:#fff;
			}
		}
	}
}

@include media-breakpoint-up(md){

	.col-lg-5-col {
		flex: 0 0 20%;
		max-width: 20%;

		position: relative;
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
	}

	.lead{
		margin-top: 1em;
		font-size:1.3em;
		line-height: 1.3em;
		margin-bottom: 1em;
	}
}

.next-previous-container{
	background-color: $brand-color;
	padding: 15px;
	position: sticky;
	top: 90px;
	left: 100%;
	width: 90px;
	font-size: 0.8em;
	a{
		height:20px;
		margin-bottom: 15px;
		color: #000;
		display: block;
		@include heading-regular();
		svg{
			margin-top: -5px;
			width: 15px;
			height: 15px;
			margin-left: 5px
		}
		& + a{
			border-top: 1px solid #000;
			padding-top: 10px;
			svg{
				transform:rotate(180deg);
				margin-left: 0;
				margin-right: 5px
			}
		}
	}
	& + .container .page-title-container.svg{
		margin-top: -180px;
	}
}

@include media-breakpoint-down(md) {
	.page-title-container{

		&.svg{
			margin-top: -120px;
			padding: 30px;
			.svg-container{
				width: 50px;
				height: 50px;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.next-previous-container{
		display: none;
	}
	.feature{

		&.feature-yellow{
			padding-top: 50px;
			padding-bottom: 50px;
			
			padding-left: 15px;
			padding-right: 15px;

		}
		&.feature-white{
			background: transparent;
			padding-bottom: 50px;
			h2{
				color: #fff;
				font-size: 1.4em;
				margin-bottom: 0.8em;
				text-transform: uppercase;
			}
		}
		.negative-margin{
			margin-top: 0;
		}
	}

	.page-title-container{

		&.svg{
			margin-top: -80px;
			padding: 20px;
			.svg-container{
				width: 40px;
				height: 40px;
				
			}
		}
	}
}

