
.search-form__results{
	padding-top: 50px;
	padding-bottom: 50px;

	h1{
		color: #fff;
    	margin-top: 0;
	}
}

.arrowlink{
	display: flex;
    align-items: center;
    color: #ffdd00;
    @include heading-regular;
	svg{
	    width: 16px;
	    fill: $brand-color;	
	    margin-right: 5px;
	}
}
.search-form{
	label{
		font-size: 16px;
	}
	form{
		grid-template-columns: 1fr max-content;
	    grid-column-gap: 15px;
	    display: grid;
	    input{
	    	margin-bottom: 0;
	    	font-size: 15px;
	    	&::placeholder{
	    		font-size: 15px;
	    	}
	    }
	}
}



.seemore{
	background-color: $brand-color;
	display: flex;
	align-items: center;
	@include heading-regular;
	span{
		svg{
			width: 30px;
		}
	}
	a{
		padding: 10px;
		height: 100%;
		width: 100%;
		color: #000;
		text-transform: uppercase;
	}

}

.card-project{
	min-height: 450px; 
	padding: 20px; 
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	&:hover{
		&:before{
			background-color: rgba(29, 29, 29, 0.2);
		}
		.card-title{
			svg{ 
				fill: $brand-color;
			}
		}
		.see-more{
			display: block;
		}
	}
	h2{
		color: #fff;
		width: 50%;
		text-transform: uppercase;
		font-size: 1.1em;
	}
	&:before{
		content: "";
		background-color: rgba(29, 29, 29, 0.6);
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	}
	.card-block{
		position: relative;
		z-index: 2;
	}
	.card-title{
		svg{
			width: 30px;
			fill: #fff;
			margin-bottom: 10px;
		}
	}
	.see-more{
		display: none;
		svg{
			width: 30px;
			fill: $brand-color;
		}
	}
}


.card-sectors{
	min-height: 500px;
	a{
		display: block;
		overflow: hidden;
		width: 100%;
		height: 100%;
	}
	img{
		margin-bottom: 10px;
	}
	h2{
		color: $brand-color;
		width: 50%;
		text-transform: uppercase;
		margin-bottom: 0;
		margin-left: 40px;
		@include heading-bold();
	}
	h3{
		white-space:nowrap;
		font-size: 1.7em;
		color: $brand-color;
		width: 50%;
		text-transform: uppercase;
		margin-bottom: 0;
		@include heading-bold();
	}
	&:before{
		content: "";
		background-color: rgba(29, 29, 29, 0.6);
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	}
	.card-block{
		position: relative;
		z-index: 2;
		height: 100%;
		width: 100%;
	}
	.card-title{

		display: flex;
		align-items: center;
		h3{
			transform:  translate(-50%, 280px) rotate(-90deg);
			position: absolute;
			left: 50%;
			transform-origin:center;
		}
		svg{
			width: 50px;
			height: 50px;
			fill: #fff;
			position: absolute;
			left: 50%;
			bottom: 80px;
			transform: translateX(-50%);
		}
	}
	
	&:hover{
		h3{
			color: #fff;
		}

		&:before {
			background-color: rgba(29, 29, 29, 0.2);
		}


	}
	
}
@include media-breakpoint-down(md) {



}

.project-card{
	padding-bottom: 50px;
	.img-container{
		margin-bottom: 30px;
		&:before {
			content: "";
			height: 100%;
			width: 100%;
			background-color: rgba(255, 255, 255, 0.4);
			position: absolute;
			z-index: 1;
			opacity: 0;
			transition: opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		}
	}

	a{
		&:hover{

			.img-container{
				&:after {
					opacity: 1;
				}
				&:before {
					opacity: 1;
				}
			}
		}
	}

	h2{
		a{
			color: #4D4F53;
			&:hover{
				color: $brand-color;
			}
		}
	}
}

.directory-filters{
	margin-top: -21px;
	background: $brand-color;
	padding: 15px 0;
	select{
		color: $brand-color;
		background: $brand-color;
		border:0;
		& + select{
			margin-left: 30px;
		}
	}
	& + .tint-bg{
		margin-top: 0;
	}
	.form-group{
		margin-bottom: 0;
		.form-control{
			background: transparent;
			border-radius: 0;
			border:0;
		}
		.input-group-addon{
			background: transparent;
			color: #fff;
			border:0;
		}
	}
}


.category-nav{
	list-style: none;
	padding-left: 0;
	margin: 30px 0 45px;
	padding-bottom: 20px;
	padding-left: 30px;
	border-bottom: 1px solid rgba(170, 165, 158, 0.30);
	position: relative;
	text-align: center;
	padding-bottom: 30px;
	li{
		display: inline-block;
		padding-bottom: 3px;
		margin-right: 20px;
		a{
			color: #AAA59E;
			@include font-bold;
			&:hover,
			&:focus{
				color: $brand-color;
			}
		}
		&.active{
			a{
				color: $brand-color;
				&:hover,
				&:focus{
					color: #AAA59E;
				}
			}

		}

		span{
			color: #b3b9b6;
			transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		}
	}
	a{
		color: $brand-color;

		&:hover{
			color: $brand-color;
			span{
				color: $brand-color;

			}
		}
	}

}
.directory-controls{
	position: absolute;
	right: 30px;
	top: 0;
	padding-top: 15px;
	a{
		font-size: 1.8em;
		background: $brand-color;
		color: #fff;
		border-radius: 50%;
		width: 48px;
		height: 48px;


		text-align: center;
		margin-right: 10px;
		display: flex;
		justify-content:center;
		align-items:center;
		float: left;
		transition:background-color 0.2s ease-in-out;
		&:hover,
		&:focus{
			background:$brand-color;
		}
	}
}


.media.document{
	margin-bottom: 50px;

	.media-left{
		width: 33%;
		display: block;
		float: left;
	}
	.media-body{
		width: 65%;

		display: block;
		float: left;
		padding-left: 15px;
		padding-bottom: 30px;
		position: relative;

		small{
			display: block;
			color: $brand-color;
			@include font-bold;
			margin-bottom: 10px;
		}
		h3{
			margin-bottom: 15px;
		}
	}

	.media-footer{
		overflow: hidden;
		width: calc(100% - 20px);
		position: absolute;
		display: flex;
		justify-content:space-between;
		bottom: 0;
		a + a{
			margin-left: 30px;
		}
		small{
			color: #999;
		}
	}
}

.project-filter{
	list-style: none;
	padding-left: 0;
	margin-bottom: 40px;
	li{
		display: inline-block;
		@include link-color(#ADAFAF, $brand-color);
		@include font-bold;
		&:not(:last-of-type){
			margin-right: 25px;
		}
		a.active{
			color: $brand-color;
			&:hover{ 
				color: #ADAFAF;
			}
		}
	}
}

.project-link{
	text-align: center;
	display: block;
	margin-bottom: 25px;
	padding-bottom: 15px;
	border-bottom: 2px solid $brand-color;
	color: #fff;
	text-transform: uppercase;
}


@include media-breakpoint-down(md) {
	.card-project{
		min-height: 300px;
	}
}


@include media-breakpoint-down(sm) {
	.seemore{
		margin-left: 15px;
		margin-right: 15px;
	}

	.card-project{
		min-height: 200px;
		margin-left: 15px;
		margin-right: 15px;
	}

	.card-sectors{
		min-height: 150px;
		margin-right: 15px;
		margin-left: 15px;
		display: flex;
		align-items: center;
		.card-title{
			padding: 15px;
			h3{
				transform: rotate(0);
				position: relative;
			}
			svg{
				position: relative;
				left: 0;
				top: 0;
				transform:translate(0);
			}
		}
	}



	.category-nav {
		li {
			margin-bottom: 10px;
		}	
	}

	.media.document{

		.media-left{
			text-align: left;
			width: 100px;
		}
		.media-body{
			padding-top: 15px;
			width: 100%;
			padding-left: 0;
		}
	}

}