////
//Variables
////

//color variables
$darker-grey :#d2d0d0;
$grey-text: rgba(33, 43, 54, 0.4);
$lighter-grey : #EEEEEC;

$linkedin-color:#0077B5;
$twitter-color:#2CAAE1;


$brand-color: #ffdd00;
$brand-highlight: #00ACD4;
$brand-grey: #ADAFAF;

$link-color: $brand-color;
$link-highlight: darken($brand-color, 10%);

$error-color: #E6302F;

//fonts
@import url('https://fonts.googleapis.com/css?family=Barlow|Barlow+Condensed');


@mixin fontawesome-light(){
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 300;

}
@mixin fontawesome-solid(){
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 900;
}
@mixin fontawesome-regular(){
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 400; 
}
@mixin fontawesome-brand(){
	font-family: "Font Awesome 5 Brands ";
	font-style: normal;
	font-weight: 400;
}

@mixin font-light(){

	font-family:  'Barlow' , Helvetica, Arial, sans-serif;
	font-weight: 300;
	letter-spacing: 0.2px;
}
@mixin font-regular(){
	font-family:  'Barlow' , Helvetica, Arial, sans-serif;
	font-weight: 400;
}
@mixin font-med(){
	font-family:  'Barlow' , Helvetica, Arial, sans-serif;
	font-weight: 500;
}
@mixin font-bold(){
	font-family: 'Barlow' ,  Helvetica, Arial, sans-serif;
	font-weight: 700;
}

@mixin font-black(){
	font-family: 'Barlow' , Helvetica, Arial, sans-serif;
	font-weight: 800;
}

@mixin heading-regular(){
	font-family: 'Barlow Condensed' , Helvetica, Arial, sans-serif;
	font-weight: 500;
}

@mixin heading-med(){
	font-family: 'Barlow Condensed' , Helvetica, Arial, sans-serif;
	font-weight: 600;
}

@mixin heading-bold(){
	font-family: 'Barlow Condensed' , Helvetica, Arial, sans-serif;
	font-weight: 700;
}


//structural variables

$animation-duration: 0.7s;