
body,html{
	scroll-behaviour: smooth;
	position: relative;
}

html{
	min-height: 100%;
}

body{
	font-size: 22px;
	@include font-regular;
	-webkit-font-smoothing:antialiased;
	background: #000;
	overflow-x:hidden;
	overflow-y:scroll; 
	
}

li{ 
	color: #fff; 
}

p{
	@include font-light;
	margin-bottom: 1em;
	line-height: 1.4em;
	font-size: 1em;
	color: #fff;

}
img{
	max-width: 100%
}
h1{
	margin-top: 1em;
	margin-bottom: 1em;
	font-size: 2em;
	line-height: 1.3em;
	color:  $brand-color;
	@include heading-regular;
	small{ 
		font-size:0.5em;
	}
} 
h2{
	margin-bottom: 1em;
	margin-top: 1em;
	font-size: 1.7em;
	@include heading-regular();
}

h2, h3{
	color:  $brand-color;
	
}
h3{
	margin-bottom: 1em;
	@include heading-regular;
}
h4, h5, h6{
	@include heading-regular;
	color: $brand-color;
	text-transform: uppercase;
}

h2{
	margin-bottom: 1em;
	font-size: 2em;
	text-transform: uppercase;
	@include heading-regular();
}


strong{
	@include font-bold;
	color: $brand-color;
}


a{
	color: $link-color;
	transition: color .2s cubic-bezier(0.455, 0.03, 0.515, 0.955);

	&:hover,
	&:focus{
		color: $link-highlight;
		text-decoration: none;
	}

	&.linkedin:hover,
	&.linkedin:focus{
		color: $linkedin-color;
	}
	&.twitter:hover,
	&.twitter:focus{
		color: $twitter-color;
	}
	&.see-more{
		@include font-bold;
		color: $brand-color;
	}

}



td{
	color: $brand-color;
}
hr{
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

blockquote{
	border-left: 0 none transparent;
	padding: 40px 0 25px;
	margin-bottom: 0;
	position: relative;
	overflow: hidden;
	@include heading-regular;
	font-size: 1.8em;
	line-height: 1.2em;
	text-align: center;

	p{
		@include heading-regular;
		font-size: 1em;
		line-height: 1.4em;
		margin-bottom: 30px;
		color: $brand-color;
	}

}

@media(max-width: 767px){
	h2{
		margin-bottom: 25px;
	}

	blockquote{

		max-width: 100%;
		font-size: 1.4em;
		line-height: 1.3em;

	}
}

@include media-breakpoint-down(md) {
	
	h1{
		font-size: 3em;
	}

	h1{
		font-size: 2.3em;
		line-height: 1.3em;
	}
	
}


@include media-breakpoint-down(sm) {

	body{
		font-size: 18px;
	}

	h1{
		font-size: 2em;
		line-height: 1.3em;
	}
	
}