.contact-info{

	padding-left: 0;
	list-style: none;
	margin-top: 50px;

	li{
		@include font-bold;
		@include link-color(#4D4F53, $brand-color);
		padding: 10px 0;
		font-size: 1.5em;
	}
}


.person-profile{
	width: 100%;
	margin-bottom: 15px;
	overflow: hidden;
	height: 0;
	padding-bottom: 100%;

	img{
		transition:0.2s ease-in-out filter;
		filter: grayscale(100%); 
		clip-path: polygon(87% 0, 100% 13%, 100% 100%, 0 100%, 0 0);
	}
	&:hover{
		img{
			filter: grayscale(0%);
		}
	}
	& + .person-details{
		text-align: center;
		margin-bottom: 30px;
	}
	&.top{
		margin-top: -130px;
		max-width: 250px;
		padding-bottom: 250px;
		
		& + .person-details{
			text-align: left;
			h2{
				margin-top: 0;
			}
		}
	}
	
}
.featured-person{
	img{
		width: 100%;
	}
}

a{
	&:hover{
		.person-details{
			h5{
				color: $brand-color;
				margin-bottom: 0;
			}
			color: $brand-color;
		}
	}
}




.person-details{
	transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	h5{
		transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		color: #fff;
		margin-bottom: 0;
	}
	color: #fff;
	h1{
		margin-bottom: 0;
	}
	h2{
		font-size: 1.4em;
	}
}

@include media-breakpoint-down(sm) {


	.featured-person{
		display: none;
	}

	.person-profile{
		padding-bottom: 0;
		height: 250px;
		img{
			max-width: 250px;
		}

		&.top{
			text-align: left;
			margin-top: -90px;

			img{

				width: 200px;
			}
		}
	}

}


